import { EventNames } from '../../core/fedops';
import { BookingsGroup } from '../../types';
import { isBookingPaidWithPricingPlan } from '../../utils/payment';
import { CreateActionParams } from '../actions';
import _ from 'lodash';
import { BookingStatus } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';

export type ClickOnCancel = ({ group }: { group: BookingsGroup }) => void;

export function createClickOnCancelAction({
  context,
  getControllerState,
}: CreateActionParams): ClickOnCancel {
  return async ({ group }) => {
    const { api, flowAPI, fedopsLogger } = context;
    const [state, setState] = getControllerState();
    const { t } = flowAPI.translations;

    fedopsLogger?.interactionStarted(EventNames.Cancel);
    const upcomingData = _.cloneDeep(state.upcomingData);
    const groups = state.upcomingData?.groups || [];

    const modifiedGroups: BookingsGroup[] = groups.filter((bookingGroup) => {
      if (group.groupId) {
        return bookingGroup.groupId !== group.groupId;
      } else {
        return (
          bookingGroup.bookings[0].bookingId !== group.bookings[0].bookingId
        );
      }
    });

    setState({
      upcomingData: {
        ...state.upcomingData,
        groups: modifiedGroups,
      },
    });

    try {
      const bookingId = group.bookings[0].bookingId;

      if (group.bookings[0]?.status === BookingStatus.WAITING_LIST) {
        // cancel service in wait list
        await api.cancelWaitlist({
          bookingId,
          sessionId: group.bookings[0]?.sessionId!,
        });
      } else if (group.groupId) {
        // cancel multi services
        await api.cancelGroup({ groupId: group.groupId });
      } else {
        // cancel single service
        await api.cancelSingle({
          bookingId,
          bookingRevision: group.bookings[0].revision,
          withRefund: isBookingPaidWithPricingPlan(group),
        });
      }

      setState({
        toastContext: {
          skin: 'success' as any,
          content: t(
            'app.my-bookings-widget.cancellation-modal.toast-success-message.label',
          ),
        },
      });
      fedopsLogger?.interactionEnded(EventNames.Cancel);
    } catch (e) {
      setState({
        upcomingData,
        toastContext: {
          skin: 'error' as any,
          content: t(
            'app.my-bookings-widget.cancellation-modal.toast-error-message.label',
          ),
        },
      });
    }
  };
}
